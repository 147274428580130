.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.demo-logo {
    width: 100%;
    max-width: 200px;
    max-height: 200px;
    display:block;
    margin:auto;
    margin-bottom: 220px;
}


.RunningTasks {
    display:flex;
}
.RunningTask {
    flex-grow: 1;
    margin: auto;
}

.select-task {
    width:100%
}

.task-summary {
    margin: 15px;
}

.side-by-side {
    display: inline-flex; 
}

.side-by-side-even {
    display: flex; 
}

.btn-control {
    width:100%
}